<template>
    <div class='guide'>
         <div class="g-box" >
             <video  
                muted
                preload  
                autoplay 
                x5-video-player-fullscreen="true" 
                x5-playsinline
                playsinline
                webkit-playsinline
                x5-video-player-type="h5" 
                id="myVideo" 
                class="g-box" 
             src="https://onebid.club/onebidguide.mp4"></video>
         </div>
    </div>
</template>



<script>
import {appversioninfo} from "@/api";
import axios from "axios"
export default {
    name:"guide",
    data(){
        return {
            show:false,
        }
    },
    methods:{
        gotoIndex(){
            if(localStorage.isLogin){
                this.$router.push({name:'main-homeIndex'})
            }else{
                console.log("ds")
                this.$router.push({name:'signin'})
            }
        }
    },
    mounted(){
        // var that = this;
        
        // console.log(video)
        // video.addEventListener('ended', function(){
        //     that.$router.push({name:'signin'})
        // });     
        var video = document.getElementById("myVideo")    
        var that = this;  
        video.addEventListener("loadedmetadata",function(){            
            that.$nextTick(()=>{
                video.play();
            })
        })   
        video.addEventListener('ended', function(){
            that.$router.push({name:'main-homeIndex'})
        });  
        
        
        // function autoPlayVideo() {
		// 	/* 自动播放视频效果，解决浏览器或者APP自动播放问题 */
		// 	function videoInBrowserHandler() {
		// 		videoPlay(true);
		// 		document.body.removeEventListener('touchstart', videoInBrowserHandler);
		// 	}
		// 	document.body.addEventListener('touchstart', videoInBrowserHandler);
		// 	/* 自动播放视频效果，解决微信自动播放问题 */
		// 	function videoInWeixinHandler() {
		// 		videoPlay(true);
		// 		document.addEventListener("WeixinJSBridgeReady", function () {
		// 			videoPlay(true);
		// 		}, false);
		// 		document.removeEventListener('DOMContentLoaded', videoInWeixinHandler);
		// 	}
		// 	document.addEventListener('DOMContentLoaded', videoInWeixinHandler);
        // }
        
        // function videoPlay(isPlay) {
		// 	var media = document.getElementById('myVideo');
		// 	if (isPlay && media.paused) {
		// 		media.play();
		// 	}
		// 	if (!isPlay && !media.paused) {
		// 		media.pause();
		// 	}
		// }

    }
}
</script>


